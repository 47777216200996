html, body {
  overflow-x: overlay;
}

.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

img.logo{
  display: inline-block!important;
  margin: 0px 5px 0px 0px;
}
.logo-title{
  font-weight: bold;
  vertical-align: middle;
}

#countdown-panel{
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 20%);
  backdrop-filter: blur(5px);
}
.hardcap{
  margin-bottom: 1.5rem;
}
#timeline-carousel div.col-md-4{
  text-align: center;
}
.client-images {
  img {
    margin: 0px auto!important;
  }
}
#features .img-fluid{
  max-width: 50%!important;
}
.basic-color{
  color: #6B8BFF;
}
@media (max-width: 992px){
  .wallet-connect-btn{
    margin-bottom: 1em;
  }
}
.modal-body ul{
 padding : 0px;
}
.modal-body ul li{
  display: flex;
  justify-content: space-between;
  line-height: 2em;
}
.modal-body ul li span{
  display: inline-block;
}